<template>
  <div class="getPoints">
    <template v-if="isSuccess === true">
      <img src="./img/success.png" alt="" />
      <div class="tip">
        <div class="icon">
          <img src="./img/success_icon.png" alt="" />
        </div>
        <div class="text success" v-if="!isOk">签到成功！</div>
        <div class="text success" v-else>甜蜜值获取成功！</div>
      </div>
    </template>
    <template v-if="isSuccess === false">
      <img src="./img/error.png" alt="" />
      <div class="tip">
        <div class="icon">
          <img src="./img/error_icon.png" alt="" />
        </div>
        <div class="text error">甜蜜值获取失败！</div>
      </div>
    </template>
    <template v-if="isSuccess === true">
      <div class="point">
        <span class="symbol">{{
          pointsData.operationType == 1 ? "+" : "-"
        }}</span>
        <span class="count">{{ pointsData.pointCount }}</span>
        <span class="unit">分</span>
      </div>
    </template>
    <div class="btnSubmit">
      <div class="submit" @click="toMyPoint">我的甜蜜值</div>
    </div>
  </div>
</template>

<script>
import { volunteerSignInUrl, getSceneParamUrl } from "./api.js";
import { getHashParam } from "@/utils/utils.js";

export default {
  name: "points",
  data() {
    return {
      isSuccess: undefined,
      isOk: false, // 是否已经可以获取积分
      pointsData: {
        operationType: undefined,
        pointCount: 0,
      },
    };
  },
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async created() {
    await this.getSceneParam();
    await this.volunteerSignIn();
  },
  methods: {
    toMyPoint() {
      this.$router.push({
        name: "mall",
        query: {
          fromGetpoint: 1,
        },
        params: {},
      });
    },
    async getSceneParam() {
      let paramId = getHashParam("paramId");
      const res = await this.$axios.get(getSceneParamUrl, {
        params: {
          paramId,
        },
      });
      if (res.code === 200 && res.success) {
        // console.log(JSON.stringify(res));
        this.params = res.data ? JSON.parse(res.data) : {};
      }
    },
    async volunteerSignIn() {
      let res = await this.$axios.post(`${volunteerSignInUrl}`, {
        userId: this.userId,
        tenantId: this.tenantId,
        ...this.params,
      });
      if (res.code === 200 && res.msg === "签到成功！") {
        this.isSuccess = true;
        this.isOk = true;
        this.pointsData = res.data;
        this.$toast("签到成功！");
      } else if (res.code === 200 && res.msg === "操作成功") {
        this.isSuccess = true;
        this.isOk = false;
        this.pointsData = res.data;
        this.$toast("操作成功");
      } else {
        let msg = res.msg || "获取甜蜜值失败";
        this.isSuccess = false;
        this.isOk = false;
        this.$toast(msg);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.getPoints {
  box-sizing: border-box;
  min-height: 100%;
  text-align: center;
  > img {
    margin-top: 180px;
    width: 328px;
    height: 198px;
    vertical-align: middle;
    overflow: hidden;
  }
  .tip {
    margin-top: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 42px;
      height: 42px;
      margin-right: 18px;
      > img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        vertical-align: middle;
      }
    }
    .text {
      font-size: 42px;
      font-weight: bold;
      &.success {
        color: #3781ff;
      }
      &.error {
        color: #e02020ff;
      }
    }
  }

  .point {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    margin-top: 32px;
    color: #347eff;
    font-size: 32px;
    font-weight: bold;
    .symbol {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      font-family: "Courier New", Courier, monospace;
    }
    .count {
      margin-right: 8px;
    }
    .unit {
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
    }
  }

  .btnSubmit {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    .submit {
      width: 150px;
      height: 66px;
      font-size: 28px;
      line-height: 66px;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
      border-radius: 10px;
    }
  }
}
</style>
